// import React, { useState } from 'react';
import React, { useEffect } from 'react';
import Button from '../ui/buttons/Button'; // Импортируем компонент кнопки
import Range from '../ui/range/Range'; // Импортируем компонент кнопки
import classes from './Dialog.module.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const DialogRanges = ({ ranges, isDisabled, setIsDisabled, setUserChoice }) => {
    const { t } = useTranslation();


    const handleCalculate = (event) => {
        event.preventDefault();
        const dialog = document.querySelector('#staircaseComfort');
        if (dialog.hasAttribute('open')) {

            setUserChoice({

                stepRiseValues: ranges[0].values,
                stepRunValues: ranges[1].values,
                headRoomValues: ranges[2].values,
                stepOverhangSpaceValues: ranges[3].values,
                blondelFormulaValues: ranges[4].values,
                stairWidthValues: ranges[5].values
            });

            dialog.close();
            setIsDisabled(false);
        }
    };

    const handleDefault = (event) => {
        event.preventDefault();

        ranges[0].setValues([150, 150]);
        ranges[1].setValues([260, 270]);
        ranges[2].setValues([1900, 2000]);
        ranges[3].setValues([40, 40]);
        ranges[4].setValues([600, 650]);
        ranges[5].setValues([900, 1000]);

        setUserChoice({
            stepRiseValues: ranges[0].values,
            stepRunValues: ranges[1].values,
            headRoomValues: ranges[2].values,
            stepOverhangSpaceValues: ranges[3].values,
            blondelFormulaValues: ranges[4].values,
            stairWidthValues: ranges[5].values
        });



    };

    const handleModalClose = () => {
        const dialog = document.querySelector('#staircaseComfort');
        dialog.close();
        setIsDisabled(false);
    };

    // Обработчик клика вне диалога
    const handleClickOutside = (event) => {
        const dialog = document.querySelector('#staircaseComfort');
        if (dialog.hasAttribute('open')) {
            if (event.target === dialog) {
                dialog.close();
                setIsDisabled(false);
            }
        }
    };

    useEffect(() => {

        // Найдем все элементы с классом .range-slider
        const rangeElements = document.querySelectorAll('.range-slider');
        // Проходим по всем элементам .range-slider
        rangeElements.forEach((rangeElement) => {
            const range = rangeElement.querySelector('.range-slider__range');
            if (!range.hasAttribute('style')) {

                const inputs = rangeElement.querySelectorAll('input');

                // Задаем значения
                const startRange = parseInt(inputs[0].getAttribute('min'));
                const endRange = parseInt(inputs[1].getAttribute('max'));

                const leftStart = parseInt(inputs[0].getAttribute('min'));
                const leftEnd = parseInt(inputs[0].getAttribute('value'));

                const segmentStart = parseInt(inputs[0].getAttribute('value'));
                const segmentEnd = parseInt(inputs[1].getAttribute('value'));

                // Вычисляем длины интервалов
                const totalRange = endRange - startRange;

                const leftRange = leftEnd - leftStart;

                const segmentRange = segmentEnd - segmentStart;

                // Вычисляем процент
                const left = (leftRange / totalRange) * 100;
                const width = (segmentRange / totalRange) * 100;

                range.style.left = `${left}%`;
                range.style.width = `${width}%`;
            }

        });

    }, []);



    return (
        <dialog onClick={handleClickOutside} id='staircaseComfort' className={classes.modal}>
            <div className={classes.modal_wraper}>
                <div className={classes.modal_container}>

                    <div className={classes.modal_items}>
                        {ranges.map((range, index) => (
                            <Range
                                key={index}
                                label={range.label}
                                min={range.min}
                                max={range.max}
                                setValues={range.setValues}
                                values={range.values}
                                em={range.em}
                                disabled={isDisabled}
                            />
                        ))}
                    </div>

                    <div className={classes.modal_buttons_container} style={isDisabled ? { display: 'none' } : {}}>
                        <Button
                            text={t('default')}
                            onClick={handleDefault}
                            type="submit"
                            icon={<RestartAltIcon />}
                            cta={false}
                            disabled={isDisabled}
                        />

                        <Button
                            text={t('apply')}
                            onClick={handleCalculate}
                            type="submit"
                            icon={<DoneAllIcon />}
                            cta={true}
                            disabled={isDisabled}
                        />
                    </div>
                </div>

            </div>
            <div onClick={handleModalClose} className={classes.modal_close_btn}><Close /></div>
        </dialog >
    );
};

export default DialogRanges;
