import { useEffect, useState } from 'react';
import './App.css';
import Form from './components/form/Form';  // Импортируем компонент формы
// import i18n from './i18n';
import { useTranslation } from 'react-i18next';

function App() {

  const { i18n } = useTranslation();
  const [appReady, setAppReady] = useState(false);

  useEffect(() => {

    // const language = KalkPro.globals.lang.code
    // i18n.changeLanguage(language);

    const userLang = navigator.language?.split('-')[0] || 'ru'; // Если не удаётся получить, устанавливаем язык по умолчанию

    // Переключаем язык на основе предпочтений пользователя
    i18n.changeLanguage(userLang);

    if (i18n.isInitialized) {
      setAppReady(true);
      // window.KalkProCalc = "U";
      // window.KalkProCalc = 'straightSaddle';
      // window.KalkProCalc = 'angle90SaddleV2';
      window.KalkProCalc = 'angle90SaddleWinderSteps';
      // window.KalkProCalc = 'angle180SaddleWinderSteps';

    }

  }, [i18n]);

  if (!appReady) {
    return <div>Loading...</div>; // Показываем индикатор загрузки, пока переводы не загружены
  }

  return (
    <div className="App">
      {appReady && (
        <Form />
      )}
    </div>
  );
}

export default App;
