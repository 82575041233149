// src/components/Form.jsx

import React, { useState, useEffect, useMemo } from 'react';
import Dropdown from '../ui/dropdown/Dropdown';
import Input from '../ui/input/Input';
import Button from '../ui/buttons/Button'; // Импортируем компонент кнопки
import Slider from '../ui/slider/Slider'; // Импортируем компонент кнопки

import DialogRanges from '../dialog/DialogRanges'; // Импортируем компонент кнопки
import DialogResult from '../dialog/DialogResult'; // Импортируем компонент кнопки

import classes from './Form.module.css';  // Импортируем CSS файл
import DoneAllIcon from '@mui/icons-material/DoneAll';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import { useTranslation } from 'react-i18next';

const Form = () => {
    const { t } = useTranslation();
    // const openedCalc = KalkPro.globals.calculator.modelName;
    const openedCalc = window.KalkProCalc;

    // Состояния для хранения значений полей
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [staircaseType, setStaircaseType] = useState(`I-Shape`); // Состояние для выбранного значения
    const [versionValue, setVersionValue] = useState('v1'); // Состояние для выбранного значения
    const [versionOptions, setVersionOptions] = useState(); // Состояние для выбранного значения

    const [inputH1Value, setInputH1Value] = useState(0); // Состояние для выбранного значения
    const [inputH2Value, setInputH2Value] = useState(0); // Состояние для выбранного значения
    const [inputAValue, setInputAValue] = useState(0); // Состояние для выбранного значения
    const [inputBValue, setInputBValue] = useState(0); // Состояние для выбранного значения
    const [inputCValue, setInputCValue] = useState(0); // Состояние для выбранного значения
    const [inputLValue, setInputLValue] = useState(0); // Состояние для выбранного значения
    const [inputTValue, setInputTValue] = useState(0); // Состояние для выбранного значения

    const [inputH1Error, setInputH1Error] = useState(false);
    const [inputH2Error, setInputH2Error] = useState(false);
    const [inputAError, setInputAError] = useState(false);
    const [inputBError, setInputBError] = useState(false);
    const [inputCError, setInputCError] = useState(false);
    const [inputLError, setInputLError] = useState(false);
    const [inputTError, setInputTError] = useState(false);

    const [isResultOpen, setIsResultOpen] = useState(false); // Новое состояние для отслеживания, открыт ли диалог

    const comfortRanges = {
        stepRiseValues: [130, 250],
        stepRunValues: [180, 310],
        headRoomValues: [1800, 2000],
        stepOverhangSpaceValues: [20, 60],
        blondelFormulaValues: [500, 700],
        stairWidthValues: [550, 2000]
    }

    const [stepRiseValues, setStepRiseValues] = useState([0, 0]); // Состояние для выбранного значения
    const [stepRunValues, setStepRunValues] = useState([0, 0]); // Состояние для выбранного значения
    const [headRoomValues, setHeadRoomValues] = useState([0, 0]); // Состояние для выбранного значения
    const [stepOverhangSpaceValues, setStepOverhangSpaceValues] = useState([0, 0]); // Состояние для выбранного значения
    const [blondelFormulaValues, setBlondelFormulaValues] = useState([0, 0]); // Состояние для выбранного значения
    const [stairWidthValues, setStairWidthValues] = useState([0, 0]); // Состояние для выбранного значения

    const [errors, setErrors] = useState([]);

    const [calcResult, setCalcResult] = useState([]);

    const [isDisabled, setIsDisabled] = useState(false); // Состояние блокировки

    const [userChoice, setUserChoice] = useState({
        stepRiseValues: [150, 150],
        stepRunValues: [260, 270],
        headRoomValues: [1900, 2000],
        stepOverhangSpaceValues: [40, 40],
        blondelFormulaValues: [600, 650],
        stairWidthValues: [900, 1000]
    });

    const dialogRanges = [
        {
            label: t('stepRiseHeight'),
            min: comfortRanges.stepRiseValues[0],
            max: comfortRanges.stepRiseValues[1],
            setValues: setStepRiseValues,
            values: stepRiseValues,
            em: "SH"
        },

        {
            label: t('stepDepth'),
            min: comfortRanges.stepRunValues[0],
            max: comfortRanges.stepRunValues[1],
            setValues: setStepRunValues,
            values: stepRunValues,
            em: "SR"
        },

        {
            label: t('headroom'),
            min: comfortRanges.headRoomValues[0],
            max: comfortRanges.headRoomValues[1],
            setValues: setHeadRoomValues,
            values: headRoomValues,
            em: "HR"
        },

        {
            label: t('stepProjection'),
            min: comfortRanges.stepOverhangSpaceValues[0],
            max: comfortRanges.stepOverhangSpaceValues[1],
            setValues: setStepOverhangSpaceValues,
            values: stepOverhangSpaceValues,
            em: "SO"
        },

        {
            label: t('blondelsFormula'),
            min: comfortRanges.blondelFormulaValues[0],
            max: comfortRanges.blondelFormulaValues[1],
            setValues: setBlondelFormulaValues,
            values: blondelFormulaValues,
            em: "BF"
        },

        {
            label: t('stairWidth'),
            min: comfortRanges.stairWidthValues[0],
            max: comfortRanges.stairWidthValues[1],
            setValues: setStairWidthValues,
            values: stairWidthValues,
            em: "BF"
        },
    ];

    const options = useMemo(() => [
        { value: 'I-Shape', label: t('staircaseStraight') },
        { value: 'L-Shape', label: t('staircaseLShape') },
        { value: 'U-Shape', label: t('staircaseUShape') },
    ], [t]);

    const lShapeVersion = useMemo(() => [
        { value: 'v1', label: t('openingRectangular') },
        { value: 'v2', label: t('openingLShape') }
    ], [t]);

    const uShapeVersion = useMemo(() => [
        { value: 'v1', label: t('openingRectangular') },
        { value: 'v2', label: t('openingLShape') },
        { value: 'v3', label: t('openingLShapeReverse') },
    ], [t]);

    const setComfortParams = {
        setStepRiseValues: setStepRiseValues,
        setStepRunValues: setStepRunValues,
        setHeadRoomValues: setHeadRoomValues,
        setStepOverhangSpaceValues: setStepOverhangSpaceValues,
        setBlondelFormulaValues: setBlondelFormulaValues,
        setStairWidthValues: setStairWidthValues
    }

    const handleSelectChange = (value) => {
        setVersionValue('v1');
        setStaircaseType(value);

        if (value !== 'I-Shape') {
            setVersionOptions(value === 'L-Shape' ? lShapeVersion : uShapeVersion);
        }
    };

    const handleVersionChange = (value) => {
        setVersionValue(value);
    };

    const images = [
        `/docs/openings-schema-pics/${staircaseType}/${versionValue}/1.jpg`,
        `/docs/openings-schema-pics/${staircaseType}/${versionValue}/2.jpg`,
        `/docs/openings-schema-pics/${staircaseType}/${versionValue}/3.jpg`,
    ];

    const handleError = (inputValue, elementSelector, errorCondition, errorMessage, setError) => {
        const element = document.querySelector(elementSelector);
        const numericValue = parseFloat(inputValue);

        const isError = errorCondition(numericValue);
        const hasErrorClass = element.classList.contains(classes.error_field);

        if (isError && !hasErrorClass) {
            element.classList.add(classes.error_field);
            setError(true);
            return { label: errorMessage.label, em: errorMessage.em, text: errorMessage.text };
        }

        if (!isError && hasErrorClass) {
            element.classList.remove(classes.error_field);
            setError(false);
        }

        return isError ? { label: errorMessage.label, em: errorMessage.em, text: errorMessage.text } : null;
    };


    const validateInput = (inputs) => {
        const errors = [];

        const values = {
            inputAValue: parseFloat(inputs.inputAValue),
            inputBValue: parseFloat(inputs.inputBValue),
            inputCValue: parseFloat(inputs.inputCValue),
            inputLValue: parseFloat(inputs.inputLValue),
            inputH1Value: parseFloat(inputs.inputH1Value),
            inputH2Value: parseFloat(inputs.inputH2Value),
            inputTValue: parseFloat(inputs.inputTValue)
        };

        // Функция для добавления ошибок
        const addError = (value, selector, label, em, errorText, setError) => {
            errors.push(handleError(value, selector, (v) => v <= 0 || isNaN(v), { label, em, text: errorText }, setError));
        };

        // Пишем проверку для каждого поля
        addError(values.inputAValue, '[data-em="A"]', t('openingLength'), 'A', t('inputEmpty'), setInputAError);
        addError(values.inputBValue, '[data-em="B"]', t('openingHeight'), 'B', t('inputEmpty'), setInputBError);

        if (staircaseType !== 'I-Shape' && versionValue !== 'v1') {
            addError(values.inputCValue, '[data-em="C"]', t('openingWidth'), 'C', t('inputEmpty'), setInputCError);
        }

        addError(values.inputLValue, '[data-em="L"]', t('allowedBaseLength'), 'L', t('inputEmpty'), setInputLError);
        addError(values.inputH1Value, '[data-em="H1"]', t('liftHeight'), 'H1', t('inputEmpty'), setInputH1Error);
        addError(values.inputH2Value, '[data-em="H2"]', t('openingHeight'), 'H2', t('inputEmpty'), setInputH2Error);
        addError(values.inputTValue, '[data-em="T"]', t('slabThickness'), 'T', t('inputEmpty'), setInputTError);

        // Дополнительные проверки для H1, H2, T
        if (values.inputH1Value > 0) {
            if (values.inputH1Value <= values.inputH2Value) {
                errors.push(handleError(values.inputH1Value, '[data-em="H1"]', (v) => v <= values.inputH2Value, { label: t('liftHeight'), em: 'H1', text: t('tooSmall') }, setInputH1Error));
            }
        }

        if (values.inputH2Value > 0) {
            if (values.inputH2Value >= values.inputH1Value) {
                errors.push(handleError(values.inputH2Value, '[data-em="H2"]', (v) => v >= values.inputH1Value, { label: t('openingHeight'), em: 'H2', text: t('tooLarge') }, setInputH2Error));
            }
        }

        if (values.inputTValue > 0) {
            if ((values.inputH1Value - values.inputH2Value) > values.inputTValue) {
                errors.push(handleError(values.inputTValue, '[data-em="T"]', (v) => (values.inputH1Value - values.inputH2Value) > v, { label: t('slabThickness'), em: 'T', text: t('tooSmall') }, setInputTError));
            } else if ((values.inputH1Value - values.inputH2Value) < values.inputTValue) {
                errors.push(handleError(values.inputTValue, '[data-em="T"]', (v) => (values.inputH1Value - values.inputH2Value) < v, { label: t('slabThickness'), em: 'T', text: t('tooLarge') }, setInputTError));
            }
        }

        return errors.filter(error => error !== null); // Убираем пустые ошибки
    };

    // Обработчик изменения значения в поле ввода
    const handleInputChange = (event, setInput) => {
        const value = event.target.value.replace(/^0+/, ''); // Убираем ведущие нули
        if (value === '' || value === '0' || Number(value) >= 0) {
            setInput(value); // Обновляем состояние, если значение валидно
        }
    };

    const handleOpenModal = (event) => {
        event.preventDefault(); // Предотвращаем стандартное поведение формы

        if (Object.keys(userChoice).length !== 0) {
            setStepRiseValues(userChoice.stepRiseValues);
            setStepRunValues(userChoice.stepRunValues);
            setHeadRoomValues(userChoice.headRoomValues);
            setStepOverhangSpaceValues(userChoice.stepOverhangSpaceValues);
            setBlondelFormulaValues(userChoice.blondelFormulaValues);
            setStairWidthValues(userChoice.stairWidthValues);
        }

        const dialog = document.querySelector('#staircaseComfort');
        dialog.showModal();
    };

    const handleCalculate = (event) => {
        event.preventDefault(); // Предотвращаем стандартное поведение формы

        // Валидация только для полей H1, H2 и T
        const errors = validateInput({
            inputAValue,
            inputBValue,
            inputCValue,
            inputLValue,
            inputH1Value,
            inputH2Value,
            inputTValue,
        });

        if (errors.length > 0) {
            // Если есть ошибки, сохраняем их в состоянии
            setErrors(errors);
        } else {
            setErrors([]);

            setTimeout(() => {


                setCalcResult([
                    {
                        angle: 32.01,
                        comfortFormula: 48.75,
                        blondelFormula: 625,
                        stepRise: 177.5,
                        stepRun: 575,
                        stepDepth: 32,
                        stepCount: 15,
                        stairsWidth: 101,
                        saddleMinWidth: 181,
                        comfortСoefficient: 0.1,
                        stepOverhangSpace: 20,
                        headRoom: 2000,
                        img: `/docs/openings-schema-pics/${staircaseType}/${versionValue}/1.jpg`
                    },
                    {
                        angle: 33.01,
                        comfortFormula: 46.75,
                        blondelFormula: 655,
                        stepRise: 137.5,
                        stepRun: 785,
                        stepDepth: 31,
                        stepCount: 14,
                        stairsWidth: 90,
                        saddleMinWidth: 131,
                        comfortСoefficient: 0.5,
                        stepOverhangSpace: 21,
                        headRoom: 2000,
                        img: `/docs/openings-schema-pics/${staircaseType}/${versionValue}/1.jpg`
                    },
                    {
                        angle: 34.01,
                        comfortFormula: 49.75,
                        blondelFormula: 565,
                        stepRise: 197.5,
                        stepRun: 535,
                        stepDepth: 29,
                        stepCount: 17,
                        stairsWidth: 98,
                        saddleMinWidth: 151,
                        comfortСoefficient: 0.8,
                        stepOverhangSpace: 22,
                        headRoom: 2000,
                        img: `/docs/openings-schema-pics/${staircaseType}/${versionValue}/1.jpg`
                    },
                    {
                        angle: 35.01,
                        comfortFormula: 42.75,
                        blondelFormula: 845,
                        stepRise: 127.5,
                        stepRun: 235,
                        stepDepth: 25,
                        stepCount: 15,
                        stairsWidth: 97,
                        saddleMinWidth: 161,
                        comfortСoefficient: 0.3,
                        stepOverhangSpace: 23,
                        headRoom: 2000,
                        img: `/docs/openings-schema-pics/${staircaseType}/${versionValue}/1.jpg`
                    },
                ]);

            }, 3000);


            const dialogResult = document.querySelector('#calcResult');
            dialogResult.showModal();
            setIsResultOpen(true);
        };
    };

    const handleShowError = (event) => {
        const error = event.target.querySelector(`.${classes.form_error_icon}`);
        if (error) {
            document.querySelector(`[data-em="${error.innerHTML}"]`)?.classList.add(classes.error_hightlight);
        }
    }

    const handleHideError = (event) => {
        const error = event.target.querySelector(`.${classes.form_error_icon}`);
        if (error) {
            document.querySelector(`[data-em="${error.innerHTML}"]`)?.classList.remove(classes.error_hightlight);
        }
    }

    const handleGoToError = (event) => {
        const error = event.target.closest(`.${classes.form_error_item}`);
        if (error) {
            const element = document.querySelector(`[data-em="${error.querySelector(`.${classes.form_error_icon}`).innerHTML}"]`);
            element?.querySelector('input')?.focus();
            element?.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // useEffect для отслеживания изменения размера окна
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        // Добавляем слушатель на изменение размера окна
        window.addEventListener('resize', handleResize);

        // Убираем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [staircaseType, versionOptions, versionValue, calcResult]); // Этот эффект не зависит от каких-либо состояний, кроме окна

    useEffect(() => {
        if (openedCalc && openedCalc !== "") {
            const stairsType = ["straight", "angle90", "angle180"];
            const shapeTypes = ['I-Shape', 'L-Shape', 'U-Shape'];

            // Приводим строку к нижнему регистру и ищем слово, соответствующее индексу
            const foundIndex = stairsType.findIndex(type => openedCalc.toLowerCase().includes(type));

            const shapeType = foundIndex !== -1 ? shapeTypes[foundIndex] : 'I-Shape'; // Если слово найдено, присваиваем соответствующий shapeType

            setStaircaseType(shapeType);
            setVersionOptions(shapeType === 'L-Shape' ? lShapeVersion : shapeType === 'U-Shape' ? uShapeVersion : undefined);
        }
    }, [openedCalc, lShapeVersion, uShapeVersion]);



    return (
        <div className={classes.wraper}>

            <div className={classes.container}>
                <div className={classes.form_container}>
                    {errors.length > 0 && (
                        <div className={classes.form_error}>
                            {errors.map((error, index) => (
                                <div key={index} className={classes.form_error_item} onMouseEnter={handleShowError} onMouseLeave={handleHideError} onClick={handleGoToError}>
                                    <div className={classes.form_error_icon}>{error.em}</div>
                                    <div className={classes.form_error_text}>
                                        <strong>{error.label}</strong><span>{error.text}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    <form className={classes.form}>

                        <Dropdown
                            options={options}
                            onChange={handleSelectChange}
                            value={staircaseType}
                            placeholder={t('selectTypeStaircase')}
                            lable={t('typeStaircase')}
                            em={'S'}
                        />

                        {staircaseType !== 'I-Shape' && (
                            <Dropdown
                                options={versionOptions}
                                onChange={handleVersionChange}
                                value={versionValue}
                                placeholder={t('selectOpeningShape')}
                                lable={t('openingShape')}
                                em={'O'}
                            />
                        )}

                        {windowWidth <= 950 && (
                            <div className={classes.image_container}>
                                <Slider images={images} />
                            </div>
                        )}

                        <Input
                            type={'number'}
                            onChange={(event) => handleInputChange(event, setInputAValue)}
                            value={inputAValue}
                            placeholder={'0'}
                            label={`${t('openingLength')}, ${t('mm')}:`}
                            em={'A'}
                            error={inputAError}
                        />

                        <Input
                            type={'number'}
                            onChange={(event) => handleInputChange(event, setInputBValue)}
                            value={inputBValue}
                            placeholder={'0'}
                            label={`${t('openingWidth')}, ${t('mm')}:`}
                            em={'B'}
                            error={inputBError}
                        />

                        {(staircaseType !== 'I-Shape' && versionValue !== 'v1') && (
                            <Input
                                type={'number'}
                                onChange={(event) => handleInputChange(event, setInputCValue)}
                                value={inputCValue}
                                placeholder={'0'}
                                label={(staircaseType === 'L-Shape') ? `${t('openingWidth')}, ${t('mm')}:` : `${t('openingLength')}, ${t('mm')}:`}
                                em={'C'}
                                error={inputCError}
                            />
                        )}

                        <Input
                            type={'number'}
                            onChange={(event) => handleInputChange(event, setInputLValue)}
                            value={inputLValue}
                            placeholder={'0'}
                            label={`${t('allowedBaseLength')}, ${t('mm')}:`}
                            em={'L'}
                            error={inputLError}
                        />

                        <Input
                            type={'number'}
                            onChange={(event) => handleInputChange(event, setInputH1Value)}
                            value={inputH1Value}
                            placeholder={'0'}
                            label={`${t('liftHeight')}, ${t('mm')}:`}
                            em={'H1'}
                            error={inputH1Error}
                        />

                        <Input
                            type={'number'}
                            onChange={(event) => handleInputChange(event, setInputH2Value)}
                            value={inputH2Value}
                            placeholder={'0'}
                            label={`${t('openingHeight')}, ${t('mm')}:`}
                            em={'H2'}
                            error={inputH2Error}
                        />

                        <Input
                            type={'number'}
                            onChange={(event) => handleInputChange(event, setInputTValue)}
                            value={inputTValue}
                            placeholder={'0'}
                            label={`${t('slabThickness')}, ${t('mm')}:`}
                            em={'T'}
                            error={inputTError}
                        />

                        <div className={classes.form_buttons_container}>
                            <Button
                                text={t('comfortCriteria')}
                                onClick={handleOpenModal}
                                type="submit"
                                icon={<SettingsSharpIcon />}
                                cta={false}
                            />

                            <Button
                                text={t('calculate')}
                                onClick={handleCalculate}
                                type="submit"
                                icon={<DoneAllIcon />}
                                cta={true}
                            />
                        </div>

                    </form>
                </div>
                {windowWidth > 950 && (
                    <div className={classes.image_container}>
                        <Slider images={images} />
                    </div>)}
            </div>


            <DialogRanges
                ranges={dialogRanges}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                setUserChoice={setUserChoice}
            />

            <DialogResult
                stairs={calcResult}
                isResultOpen={isResultOpen}
                setIsResultOpen={setIsResultOpen}
                setIsDisabled={setIsDisabled}
                setComfortParams={setComfortParams}
            />

        </div>
    );
}

export default Form;
