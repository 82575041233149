import React, { useState, useRef, useEffect } from 'react';
import Card from '../card/Card';
import Dropdown from '../ui/dropdown/Dropdown';
import classes from './Dialog.module.css';
import Close from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const DialogResult = ({ stairs, isResultOpen, setIsResultOpen, setIsDisabled, setComfortParams }) => {
    const { t } = useTranslation();

    const [filterType, setFilterType] = useState('0'); // По умолчанию сортировка по возрастанию
    const [filter, setFilter] = useState('comfortСoefficient'); // По умолчанию фильтрация по `stepRise`
    const [isHeightExceeded, setIsHeightExceeded] = useState(false);
    const [filteredStairs, setFilteredStairs] = useState(stairs); // Для хранения отфильтрованных лестниц
    const cardsRef = useRef(null);  // Ссылка на контейнер карточек

    const optionsFilterType = [
        { value: '0', label: t('descendingOrder') },
        { value: '1', label: t('ascendingOrder') },
    ];

    const optionsFilter = [
        { value: 'comfortСoefficient', label: t('comfortCoefficient') },
        { value: 'angle', label: t('angleInclination') },
        { value: 'comfortFormula', label: t('comfortFormula') },
        { value: 'blondelFormula', label: t('blondelsFormula') },
        { value: 'stepRise', label: t('stepHeight') },
        { value: 'stepRun', label: t('stepWidth') },
        { value: 'stepDepth', label: t('stepDepth') },
        { value: 'stepCount', label: t('numberSteps') },
        { value: 'stairsWidth', label: t('stairWidth') },
        { value: 'saddleMinWidth', label: t('minimumStringerWidth') },
    ];

    const handleModalClose = () => {
        const dialog = document.querySelector('#calcResult');
        dialog.close();
        setIsResultOpen(false); // Закрытие диалога
    };

    const handleClickOutside = (event) => {
        const dialog = document.querySelector('#calcResult');
        if (dialog.hasAttribute('open') && event.target === dialog) {
            dialog.close();
            setIsResultOpen(false); // Закрытие диалога
        }
    };

    const handleFilterType = (value) => {
        setFilterType(value);
    };

    const handleFilter = (value) => {
        setFilter(value);
    };

    // Проверка при открытии диалога, только один раз
    useEffect(() => {
        if (!isResultOpen) return; // Если диалог не открыт, не делаем проверку

        const intervalId = setInterval(() => {
            if (cardsRef.current) {
                const { scrollHeight } = cardsRef.current;
                if (scrollHeight > 0) { // Проверка только если элемент отрисован
                    const exceeded = scrollHeight > window.innerHeight * 0.65;
                    setIsHeightExceeded(exceeded);
                    clearInterval(intervalId); // Прекращаем проверку после первого успешного измерения
                }
            }
        }, 50);

        return () => clearInterval(intervalId); // Очищаем интервал при размонтировании или изменении `isResultOpen`
    }, [isResultOpen]); // Запускаем эффект только при открытии диалога


    // Фильтрация списка stairs в зависимости от выбранного поля и типа сортировки
    useEffect(() => {
        let sortedStairs = [...stairs]; // Копируем массив для сортировки

        if (filterType === '0') {
            // Сортировка по убыванию
            sortedStairs.sort((a, b) => b[filter] - a[filter]);
        } else if (filterType === '1') {
            // Сортировка по возрастанию
            sortedStairs.sort((a, b) => a[filter] - b[filter]);
        }

        setFilteredStairs(sortedStairs);
    }, [filterType, filter, stairs]);

    return (
        <dialog onClick={handleClickOutside} id="calcResult" className={classes.modal} open={isResultOpen}>
            <div className={classes.modal_wraper}>
                <div className={classes.modal_container}>
                    <div className={classes.modal_title}>
                        <label className={classes.heading}>{(t('calculationResults')).toUpperCase()}</label>
                    </div>

                    <Dropdown
                        options={optionsFilterType}
                        onChange={handleFilterType}
                        value={filterType}
                        placeholder={t('selectType')}
                        lable={`${t('filterType')}:`}
                        em={'FT'}
                    />
                    <Dropdown
                        options={optionsFilter}
                        onChange={handleFilter}
                        value={filter}
                        placeholder={t('selectFilter')}
                        lable={`${t('filterBy')}:`}
                        em={'FV'}
                    />

                    <div className={classes.modal_title}>
                        <label className={classes.label}>{t('configurationsFound')}: <strong>{filteredStairs.length}</strong></label>
                    </div>

                    <div
                        ref={cardsRef}
                        className={`${classes.modal_items} ${classes.cards}`}
                        style={{
                            paddingRight: isHeightExceeded ? '5px' : '0', // Используем инлайновый стиль для паддинга
                        }}
                    >
                        {(filteredStairs.length === 0) && (
                            <>
                                <div className={classes.card_preloader}></div>
                                <div className={classes.card_preloader}></div>
                                <div className={classes.card_preloader}></div>
                                <div className={classes.card_preloader}></div>
                            </>
                        )}

                        {filteredStairs.map((stair, index) => (
                            <Card
                                key={index}
                                stair={stair}
                                setIsDisabled={setIsDisabled}
                                setComfortParams={setComfortParams}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div onClick={handleModalClose} className={classes.modal_close_btn}>
                <Close />
            </div>
        </dialog>
    );
};

export default DialogResult;
