// src/components/Button/Button.jsx

import React from 'react';
import classes from './Button.module.css'; // Импортируем стили

const Button = ({
    onClick,
    text,
    type = 'button',
    disabled = false,
    icon,
    cta
}) => {
    return (
        <div className={classes.button_wrapper}>
            <div className={classes.button_container}>
                <button
                    onClick={onClick}
                    type={type}
                    disabled={disabled}
                    className={`${classes.button} ${(cta) ? classes.cta : ''}`} // Используем стили + дополнительный класс
                >
                    {icon && <span className={classes.button_icon}>{icon}</span>} {/* Отображаем иконку если она передана */}
                    <span className={classes.button_text}>{text}</span>
                </button>
            </div>
        </div>
    );
};

export default Button;
