import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
    .use(HttpBackend) // Подключаем backend для загрузки переводов
    .use(initReactI18next) // Подключаем react-i18next
    .init({
        backend: {
            // Указываем путь к переводу
            loadPath: '/locales/{{lng}}/i18n.json',
        },
        lng: false, // Язык по умолчанию
        fallbackLng: false, // Язык по умолчанию, если не найдено соответствие
        load: 'currentOnly',  // Ожидаем загрузку только текущего языка
        interpolation: {
            escapeValue: false, // Не экранировать значения
        },
    });

export default i18n;
