import React from 'react';
import classes from './Card.module.css'; // Импортируем стили для карточки
import { useTranslation } from 'react-i18next';


const Card = ({ stair, setIsDisabled, setComfortParams }) => {
    const { t } = useTranslation();

    const handleLink = (link) => {
        window.open(link, "_blank");
    }

    const handleComfort = () => {
        setComfortParams.setStepRiseValues([stair.stepRise, stair.stepRise]);
        setComfortParams.setStepRunValues([stair.stepRun, stair.stepRun]);
        setComfortParams.setHeadRoomValues([stair.headRoom, stair.headRoom]);
        setComfortParams.setStepOverhangSpaceValues([stair.stepOverhangSpace, stair.stepOverhangSpace]);
        setComfortParams.setBlondelFormulaValues([stair.blondelFormula, stair.blondelFormula]);
        setComfortParams.setStairWidthValues([stair.stairsWidth, stair.stairsWidth]);

        const dialog = document.querySelector('#staircaseComfort');
        dialog.showModal();
        setIsDisabled(true);
    }

    // Функция для определения цвета в зависимости от значения comfortСoefficient
    const getComfortColor = (comfortCoefficient) => {
        if (comfortCoefficient <= 0.33) {
            return '#ff4848'; // Красный цвет для первой трети
        } else if (comfortCoefficient <= 0.66) {
            return '#ff9800'; // Оранжевый цвет для второй трети
        } else {
            return '#36b624'; // Зеленый цвет для третьей трети
        }
    };

    // Получаем цвет для коэффициента комфорта
    const comfortColor = getComfortColor(stair.comfortСoefficient);

    return (
        <div className={classes.card_link}>
            <div className={classes.card}>
                <img
                    src={stair.img}
                    alt="Stair"
                    className={classes.card_image}
                    onClick={() => handleLink("#")}
                />
                <div className={classes.card_info} onClick={handleComfort}>
                    <span style={{ color: comfortColor }}>
                        {t('comfortCoefficient')}: <strong>{stair.comfortСoefficient}</strong>
                    </span>
                    <span>{t('angleInclination')}: <strong>{stair.angle}°</strong></span>
                    <span>{t('comfortFormula')} (h + s): <strong>{stair.comfortFormula}</strong></span>
                    <span>{t('blondelsFormula')} (2h + s): <strong>{stair.blondelFormula}</strong></span>

                    <span>{t('stepHeight')}: <strong>{stair.stepRise} {t('mm')}</strong></span>
                    <span>{t('stepWidth')}: <strong>{stair.stepRun} {t('mm')}</strong></span>
                    <span>{t('stepDepth')}: <strong>{stair.stepDepth} {t('mm')}</strong></span>
                    <span>{t('numberSteps')}: <strong>{stair.stepCount}</strong></span>
                    <span>{t('stairWidth')}: <strong>{stair.stairsWidth} {t('mm')}</strong></span>
                    <span>{t('minimumStringerWidth')}: <strong>{stair.saddleMinWidth} {t('mm')}</strong></span>
                </div>
            </div>
        </div>
    );
};

export default Card;