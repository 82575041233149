import React, { useState, useRef } from 'react';

const Slider = ({ images }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [startTouch, setStartTouch] = useState(0); // Для отслеживания начала свайпа
    const [isTouching, setIsTouching] = useState(false); // Для проверки, что идет свайп
    const sliderRef = useRef(null);

    // Функция обработки движения мыши
    const handleMouseMove = (e) => {
        if (!sliderRef.current) return;

        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const mouseX = e.clientX - slider.getBoundingClientRect().left;

        // Рассчитываем индекс на основе положения мыши
        const index = Math.floor((mouseX / sliderWidth) * images.length);

        // Ограничиваем индекс в пределах допустимых значений
        const boundedIndex = Math.max(0, Math.min(index, images.length - 1));

        setCurrentImageIndex(boundedIndex);
    };

    // Функции для обработки касаний на мобильных устройствах
    const handleTouchStart = (e) => {
        setStartTouch(e.touches[0].clientX); // Начальная позиция касания
        setIsTouching(true);
    };

    const handleTouchMove = (e) => {
        if (!isTouching) return;

        const touchMove = e.touches[0].clientX; // Текущая позиция касания
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;

        // Рассчитываем индекс на основе противоположного направления свайпа
        const diff = startTouch - touchMove;  // Изменение: инвертируем направление
        const index = Math.floor(((diff + sliderWidth / 2) / sliderWidth) * images.length);

        // Ограничиваем индекс в пределах допустимых значений
        const boundedIndex = Math.max(0, Math.min(index, images.length - 1));

        setCurrentImageIndex(boundedIndex);
    };

    const handleTouchEnd = () => {
        setIsTouching(false); // Завершаем свайп
    };

    return (
        <div
            ref={sliderRef}
            style={{
                position: 'relative',
                overflow: 'hidden',
                cursor: 'pointer',
            }}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <img
                src={images[currentImageIndex]}
                alt="slider"
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-out',
                }}
            />

            {/* Индикация снизу с отступами */}
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '0',
                    width: 'calc(100% - 20px)',
                    height: '5px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: '10px',  // Отступ слева
                    paddingRight: '10px', // Отступ справа
                }}
            >
                {images.map((_, index) => (
                    <div
                        key={index}
                        style={{
                            width: `${(100 - 20) / images.length}%`, // Уменьшаем ширину отрывков для учета отступов
                            height: '100%',
                            backgroundColor: index === currentImageIndex ? '#ffba00' : 'rgba(255, 255, 255, 0.3)',
                            transition: 'background-color 0.3s ease-out',
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default Slider;
