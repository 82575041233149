import React, { useRef } from 'react';
import classes from './Input.module.css';  // Импортируем CSS файл
import Warning from '@mui/icons-material/Warning';

const Input = ({ type, onChange, value, placeholder, label, em, error }) => {
    const selectRef = useRef(null);  // Ссылка на корневой элемент компонента

    return (
        <div ref={selectRef} className={classes.input_container} data-em={em}>
            <label htmlFor="input">
                {error && (
                    <div className={classes.error_icon}>
                        <Warning fontSize='small' />
                    </div>
                )}
                <span>{label}</span>
            </label>
            <div className={classes.input_field_container}>
                <div className={classes.input_field_prefix}><em className={classes.key_label}>{em}</em></div>
                <input
                    onChange={onChange}  // Передаем обработчик события
                    className={classes.input_field}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                />
            </div>
        </div>
    );
};

export default Input;
