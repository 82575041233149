import React, { useState, useEffect, useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classes from './Dropdown.module.css';  // Импортируем CSS файл
import CheckIcon from '@mui/icons-material/Check';

const Dropdown = ({ options, onChange, value, placeholder, lable, em }) => {
    const [isOpen, setIsOpen] = useState(false);  // Состояние для открытия/закрытия списка
    const selectRef = useRef(null);  // Ссылка на корневой элемент компонента

    // Функция для переключения видимости списка
    const toggleDropdown = () => setIsOpen(!isOpen);

    // Закрыть выпадающий список при клике вне компонента
    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsOpen(false);  // Закрываем выпадающий список, если клик был вне компонента
        }
    };

    // Добавляем слушатель события на клик
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);  // Убираем слушатель при размонтировании компонента
        };
    }, []);

    // Обработчик клика на опцию
    const handleOptionClick = (optionValue) => {
        onChange(optionValue);  // Передаем выбранное значение
        setIsOpen(false);  // Закрываем список после выбора
    };

    return (
        <div ref={selectRef} className={classes.dropdown_container}>
            <label htmlFor="dropdown">{lable}</label>
            <div className={classes.dropdown_field_container}>
                <div className={classes.dropdown_field_prefix}><em className={classes.key_label}>{em}</em></div>
                <div className={`${classes.dropdown_field} ${isOpen ? classes.dropdown_field_open : ''}`} onClick={toggleDropdown}>
                    <span>{value ? options.find(option => option.value === value)?.label : placeholder}</span>
                </div>
                {isOpen && (
                    <div className={classes.dropdown_options}>
                        {options.map((option) => (
                            <div
                                key={option.value}
                                className={`${classes.dropdown_option} ${option.value === value ? classes.selected : ''}`}
                                onClick={() => handleOptionClick(option.value)}
                            >
                                {option.label}
                                {(option.value === value) && (<CheckIcon className={classes.selected_icon} />)}
                            </div>
                        ))}
                    </div>
                )}
                <ExpandMoreIcon className={classes.dropdown_icon} />
            </div>
        </div>
    );
};

export default Dropdown;
