import React from 'react';
import RangeSlider from 'react-range-slider-input';
import classes from './Range.module.css';
import 'react-range-slider-input/dist/style.css'; // Импортируем стили для слайдера
import './Range.css'; // Ваш кастомный стиль для Range

const Range = ({ label, min, max, setValues, values, em, disabled }) => {

    const handleRangeChange = (newValues) => {
        setValues(newValues); // Обновляем родительское состояние
    };

    return (
        <div className={classes.range_container}>
            <div className={classes.range_field_prefix}>
                <em className={classes.key_label}>{em}</em>
            </div>
            <div className={classes.range_field_container}>
                <label htmlFor="range" className={classes.range_label}>
                    {label}: <span className={classes.range_value}>{values[0]} - {values[1]}</span>
                </label>
                <RangeSlider
                    min={min}
                    max={max}
                    step={1}
                    value={values} // Используем `value` для динамического обновления значений
                    onInput={handleRangeChange} // Обработчик для обновления значений
                    rangeSlideDisabled={false} // Разрешаем движение диапазона
                    className={classes.rangeSlider} // Класс для слайдера
                    disabled={disabled}
                />
            </div>
        </div>
    );
};

export default Range;
